import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { authorizedGet } from '../../fetchFunctions';
import { Loader } from '@mantine/core';
import { formateExactDate } from '../../utils';

const Recipes = ({ setChosenMenuItem }) => {
	const [searchInvoice, setSearchInvoice] = useState('');
	const accessToken = localStorage.getItem('token');
	const { data: recipes, isLoading } = useQuery('recipes', () =>
		authorizedGet('/publisher_invoices')
	);
	const [sortBy, setSortBy] = useState('number');
	const [numberAsc, setNumberAsc] = useState(true);
	const [dateAsc, setDateAsc] = useState(false);

	const sortRecipes = (a, b) => {
		if (sortBy === 'number') {
			return numberAsc
				? +a.invoiceNumber - +b.invoiceNumber
				: +b.invoiceNumber - +a.invoiceNumber;
		}
		if (sortBy === 'date') {
			return dateAsc
				? new Date(a.invoiceDate) - new Date(b.invoiceDate)
				: new Date(b.invoiceDate) - new Date(a.invoiceDate);
		}
	};

	const getPdf = (invoice) => {
		if (invoice.attachedFile.contentUrl) {
			try {
				let httpClient = new XMLHttpRequest();
				let fileLink = `${invoice.attachedFile.contentUrl}`;
				httpClient.open('get', fileLink, true);
				httpClient.setRequestHeader('Authorization', `Bearer ${accessToken}`);
				httpClient.responseType = 'blob';
				httpClient.onload = function () {
					const file = new Blob([httpClient.response], {
						type: 'application/png',
					});
					const fileURL = URL.createObjectURL(file);
					const link = document.createElement('a');
					link.href = '#';
					link.download = `${invoice.invoiceNumber}.png`;
					link.click();
				};
				httpClient.send();
			} catch (e) {}
		}
	};

	const download = (e) => {
		fetch(e, {
			method: 'GET',
			headers: {},
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', e.split('/')[4]);
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {});
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="invoices">
					<div className="invoices__head-wrapper">
						<div className="invoices__head-left">
							<h2 className="main-title main-title--25">Dokumenty księgowe</h2>
							<div className="main-text wysiwyg">
								<p>
									Poniżej znajdują się dokumenty wystawione do Twoich pobrań.
								</p>
							</div>
						</div>

						<div className="invoices__head-right">
							<div className="form">
								<div className="input-wrapper">
									<input
										onChange={(e) => setSearchInvoice(e.target.value)}
										type="text"
										name="f-search"
										placeholder="Numer faktury"
										className="form__input search-input required"
									/>
									<button type="button">
										<i className="ico ico-search-icon"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
					{isLoading && <Loader />}
					{recipes?.length === 0 && (
						<div className="invoices-table-container">
							<span className="btn btn--sort desc">
								Brak dokumentów księgowych.
							</span>
							<button
								onClick={() => setChosenMenuItem('Dodaj fakturę')}
								type="button"
								className="btn btn--block"
								style={{ width: 'fit-content', marginTop: 12 }}
							>
								Dodaj Dokument księgowy
							</button>
						</div>
					)}
					{!isLoading && recipes?.length > 0 && (
						<div className="invoices-table-container">
							<table className="invoices-table">
								<thead>
									<tr className="filters">
										<th className="th-document">
											<a
												onClick={() => {
													setNumberAsc((prevState) => !prevState);
													setSortBy('number');
												}}
												href="/"
												className={`btn btn--sort ${
													numberAsc ? 'desc' : 'asc'
												}`}
											>
												<span>NR DOKUMENTU</span>
												<i className="ico ico ico-chevron-thin-down"></i>
											</a>
										</th>
										<th className="th-date">
											<a
												onClick={() => {
													setDateAsc((prevState) => !prevState);
													setSortBy('date');
												}}
												href="#"
												className={`btn btn--sort ${dateAsc ? 'desc' : 'asc'}`}
											>
												<span>Data wystawienia</span>
												<i className="ico ico ico-chevron-thin-down"></i>
											</a>
										</th>
										<th className="th-action"></th>
									</tr>
								</thead>
								<tbody>
									{recipes
										?.filter((i) => {
											return i.invoiceNumber
												.toLocaleLowerCase()
												.includes(searchInvoice.toLocaleLowerCase());
										})
										?.sort(sortRecipes)
										?.map((invoice) => {
											return (
												<tr>
													<td className="td-document">
														<span className="invoices-table__rwd-label">
															Nr dokumentu
														</span>
														{invoice?.invoiceNumber}
													</td>
													<td className="td-date">
														<span className="invoices-table__rwd-label">
															Data wystawienia
														</span>
														{formateExactDate(invoice?.invoiceDate)}
													</td>
													<td className="td-action">
														<span
															style={{ cursor: 'pointer' }}
															onClick={() =>
																download(invoice.attachedFile.contentUrl)
															}
															className="invoices__download"
															target="_blank"
														>
															<i className="ico ico-download-icon"></i>
															<span>Pobierz</span>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default Recipes;
